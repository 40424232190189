import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { instance } from 'src/common/instance';

const MyComponent = () => {
  const userToken = useSelector((state) => state.auth.auth.token);

  const [userData, setUserData] = useState({
    name: '',
    email: '',
    firstName: '',
    lastName: '',
    mobile_no: '',
    city: '',
  });

  const fetchData = async () => {
    try {
      const response = await instance.get('user/user-ProfileDetail', {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response.status === 200) {
        setUserData(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleUpdateProfile = async () => {
    try {
      const response = await instance.post('user/user-UpdateProfile', userData, {
        headers: {
          Authorization: `Bearer ${userToken}`,
         
        },
      });
      console.log('Response:', response);
    } catch (error) {
      console.error('Error updating user profile:', error);
    }
    
  };

  return (
    <>
      <div className="wrapper ">
        <div className="main-panel">
          <div className="content">
            <div className="row">
              <div className="col-md-4">
                <div className="card card-user text-center mx-auto">
                  <div className="image" style={{ position: 'relative' }}>
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsXfqvnPKv7QY8h1S5_mFw00G0bs9yO3ndUw&usqp=CAU"
                      alt="..."
                    />
                  </div>
                  <div className="card-body">
                    <div className="author">
                      <h6 className="description text-center " style={{ fontSize: '1.5rem' }}>
                        {userData.firstName} {userData.lastName}
                      </h6>
                    </div>
                  </div>
                  <div className="card-footer mx-auto text-center cursor-pointer">
                    <hr />
                    <div className="button-container mx-auto text-center ">
                      <button type="button" class="btn btn-outline-success">
                        Upgrade to Premium
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="card card-user">
                  <div className="card-header" style={{ marginBottom: '1.5rem' }}>
                    <h5 className="card-title mb-4">Profile</h5>
                  </div>
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-md-6 pl-1">
                          <div className="form-group">
                            <label htmlFor="Email" style={{ fontSize: '1.1rem' }}>
                              Email Address
                            </label>
                            <input
                              type="email"
                              className="form-control rounded-lg"
                              placeholder="Email"
                              value={userData.email}
                              onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                              style={{
                                borderRadius: '0.5rem',
                                outline: 'none',
                                boxShadow: '0 0 2px rgba(169, 169, 169, 1)',
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-6 pr-1">
                          <div className="form-group">
                            <label style={{ fontSize: '1.1rem' }}>First Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="First Name"
                              value={userData.firstName}
                              onChange={(e) =>
                                setUserData({ ...userData, firstName: e.target.value })
                              }
                              style={{
                                borderRadius: '0.5rem',
                                outline: 'none',
                                boxShadow: '0 0 2px rgba(169, 169, 169, 1)',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 pl-1">
                          <div className="form-group">
                            <label style={{ fontSize: '1.1rem' }}>Last Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Last Name"
                              value={userData.lastName}
                              onChange={(e) =>
                                setUserData({ ...userData, lastName: e.target.value })
                              }
                              style={{
                                borderRadius: '0.5rem',
                                outline: 'none',
                                boxShadow: '0 0 2px rgba(169, 169, 169, 1)',
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label style={{ fontSize: '1.1rem' }}>Mobile no</label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Mobile no"
                              value={userData.mobile_no}
                              onChange={(e) =>
                                setUserData({ ...userData, mobile_no: e.target.value })
                              }
                              style={{
                                borderRadius: '0.5rem',
                                outline: 'none',
                                boxShadow: '0 0 2px rgba(169, 169, 169, 1)',
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4 pr-1">
                          <div className="form-group">
                            <label style={{ fontSize: '1.1rem' }}>City</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="City"
                              value={userData.city}
                              onChange={(e) => setUserData({ ...userData, city: e.target.value })}
                              style={{
                                borderRadius: '0.5rem',
                                outline: 'none',
                                boxShadow: '0 0 2px rgba(169, 169, 169, 1)',
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row ">
                        <div className="col-md-4 pr-1 mt-4">
                          <div>
                            <button
                             
                              className="btn  btn-round"
                              onClick={handleUpdateProfile}
                              style={{ backgroundColor: '#5D87FF', color: '#fff' }}
                            >
                              Update Profile
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyComponent;
