import { createSlice } from '@reduxjs/toolkit';
import { LOGOUT } from './rootSlice';

const INITIAL_STATE = {
  auth: {},
};

const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    SET_AUTH: (state, action) => {
      state.auth = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(LOGOUT, (state) => {
      state.auth = INITIAL_STATE.auth;
    });
  },
});

export const { SET_AUTH } = authSlice.actions;
export default authSlice.reducer;
