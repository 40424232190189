import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element }) => {
  const userToken = useSelector((state) => state.auth.auth.token);

  if (userToken) {
    return element;
  } else {
    return <Navigate to="/auth/login" />;
  }
};

export default ProtectedRoute;
